import * as React from "react"
import Sidebar from "./sidebar"
import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/global.js'
import { graphql, StaticQuery } from "gatsby"
import { getImageURL } from "../format-date"
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight"
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactMarkdown from 'react-markdown';
import menubar from '../images/icons/white-menu.png'
import getlogo from '../images/icons/logo.png'

import { Link } from "gatsby"
const GET_HOME_PAGE_DATA = graphql`
query GetHomePageData {
  strapiLandingPage{
    page_info
    seo_title
    seo_description
    seo_image{
      localFile{
        name
        extension
      }
    }
  }
  allStrapiCategories(sort:{order:ASC,fields:[sort_order]}) {
    nodes {
      category_slug
      category_name
    }
  }
  allStrapiSubCategories{
    nodes{
      sub_category_name
      sub_category_slug
      category{
        category_slug
      }
    }
  }
}

`

const IndexPage = () => (
  <StaticQuery query={GET_HOME_PAGE_DATA} render={data => {
    let categories = data.allStrapiCategories.nodes;
    const subCategories = data.allStrapiSubCategories.nodes;
    categories.forEach(category => {
      const subCategoryList = subCategories.filter(subCategory => subCategory.category.category_slug === category.category_slug);
      if(subCategoryList.length){
        category.subCategories = subCategoryList;
        category.showSubCategory = false;
      }
    });
    console.log("MAIN PAGE");
    const seoData = {
      title:data.strapiLandingPage.seo_title,
      description:data.strapiLandingPage.seo_description,
      imageUrl:getImageURL(data.strapiLandingPage.seo_image.localFile.name,data.strapiLandingPage.seo_image.localFile.extension),
      route:""
    }
          function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        );
    }
      function highLightCodeSyntax(props) {
        return (
          <SyntaxHighlighter language="dart" style={atomOneDark}>
            {props.children[0].props.children[0]}
          </SyntaxHighlighter>
        );
    }
    return <Layout>
    <Seo metaInfo={seoData} />
   <div className="header-class">
   <ul className="header-ul">
     <li className="logo">
    <Link to="/"> <img src={'https://ik.imagekit.io/ionicfirebaseapp/getwidget1_aFuUVzToL.png'} loading="lazy"  className=" logoimg"/> </Link>
     </li>
     <li>
       <div className="li-links">
         <ul>
           <li><Link to="/">Home</Link></li>
          <li><Link to="https://www.getwidget.dev/blog/" target="_blank">Blog</Link> </li>
     <li><Link to="https://github.com/ionicfirebaseapp/getwidget" target="_blank">Github</Link> </li>
     <li><Link to="https://market.getwidget.dev/products/" target="_blank">Flutter App Kit</Link></li>
         </ul>
       </div>
        </li>
     

   </ul>
   </div>

   <div className="mobile-header">
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-2 col-2 pt-2">
         <nav className="mobile-links">
       
       <label htmlFor="menu-checkbox" className="menu-btn">
        
           <input
           type="checkbox"
           id="menu-checkbox"
           />
         
           <div className="hamburger-menu">
           <img src={menubar} className="menu-img"/> 
           </div>
        
           <ul className="menu-items">
           
             {categories.map(category => (
               category.subCategories ? <div className="submenu-ul" key={category.category_slug}>
               <ul className="submenu-ul-list">
                 <li><Link to={`/${category.category_slug}/`} activeClassName="activeRoute">{category.category_name}</Link></li>
                 <div className="submenu-items">
                   <ul>
                     {category.subCategories.map(subCategory => (
                       <li><Link to={`/${category.category_slug}/${subCategory.sub_category_slug}/`} key={subCategory.sub_category_slug} activeClassName="activeRoute">{subCategory.sub_category_name}</Link></li>
                     ))}
                   </ul>
                 </div>
               </ul>
  
             </div> :
              <li>
                <Link to={`/${category.category_slug}/`} key={category.category_slug} activeClassName="activeRoute">{category.category_name}</Link>
              </li>
             ))}
              
           </ul>
        
       </label>
   
   </nav>
         </div>
     
       
         <div className="col-md-6 col-8 pt-2 pb-2">
         <div className="logo-name">
         <Link to="/"> <img src={'https://ik.imagekit.io/ionicfirebaseapp/getwidget1_aFuUVzToL.png'} loading="lazy"  className=" logoimg"/> </Link>
         </div>
         </div>
      
       </div>
     </div>

 
  
   </div>

   <div className="container-fluid">
     <div className="row">
       <div className="col-md-2 sidebar-bg pl-0">
       <Sidebar  />
       </div>
       <div className="col-md-6 offset-md-2">
       <div className="scrollable-block">
          <ReactMarkdown children={data.strapiLandingPage.page_info} components={{a:LinkRenderer,pre:highLightCodeSyntax}} />
        </div>
       </div>
     </div>
   </div>
   
  </Layout>
  }}></StaticQuery>
)

export default IndexPage
